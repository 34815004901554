import React, { Props, Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { registerAccount } from '../Actions/WebsiteActions'
import { Redirect } from 'react-router-dom'
import Header from './Common/Header'
import Footer from './Common/Footer'
import ReCAPTCHA from "react-google-recaptcha"
import firebase from "firebase"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Helmet} from "react-helmet";

const mapStateToProps = (state) => {
    return {
        clientSignUpComplete: state.Website.clientSignUpComplete,
        loggedIn: state.Auth.loggedIn
    }
}

const mapDispatchToProps = dispatch => ({
    createAccount: (data, UserType) => dispatch(registerAccount(data, UserType))
});

const getValidate = (state) => {
    var first_name = null
    var last_name = null
    var email = null
    var password = null
    var confim_password = null
    var valid = true
    var passwordEmpty = true

    if (state.first_name == null || state.first_name == "") {
        first_name = "Required"
        valid = false
    }

    if (state.last_name == null || state.last_name == "") {
        last_name = "Required"
        valid = false
    }
    if (state.email == null || state.email == "") {
        email = "Required"
        valid = false
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        email = "invalid email address"
        valid = false
    }
    if (state.password == null || state.password == "") {
        password = "Required"
        valid = false
        passwordEmpty = false
    }
    if (state.confim_password == null || state.confim_password == "") {
        confim_password = "Required"
        valid = false
        passwordEmpty = false
    }

    if ( passwordEmpty && state.password !== state.confim_password) {
        password = "Passwords do not match"
        confim_password = "Passwords do not match"
        valid = false
    }
    return { data:{first_name: first_name, last_name: last_name, email: email, password: password, confim_password: confim_password}, valid }
}

class SupplierSignUp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            confim_password: null,
            robot: false,
            capture: null,
            error: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                confim_password: null
            }
        }
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,

        ],
        callbacks: {
            signInSuccess: () => false
        }
    }

    handleUpdate = (event, field) => {
        this.setState({
            [field]: event.target.value,
            error: {
                ...this.state.error,
                [field]: null
            }
        })
    }

    doRegistration = () => {

        if(getValidate(this.state).valid && this.state.robot) {
            this.props.createAccount(this.state, 3)
        } else if(!getValidate(this.state).valid) {
            this.setState({ error: getValidate(this.state).data })
        }

        if (!this.state.robot) {
            this.setState({ capture: "Complete the Captcha before proceeding" })
            return false
        }
        
    }


    componentDidMount() {
        window.scrollTo(0, 0)
    }



    componentDidMount = () => {

        firebase.auth().onAuthStateChanged(user => {
            if (user !== null && this.props.loggedIn === false) {
                this.props.LoginWithSocialMedia(user);
            }

        })
    }

    handleRecaptcha = (value) => {
        this.setState({ robot: value, capture: false })
    }
    
    render() {
        return <Fragment>
            <Header />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Account Sign-up</title>
            </Helmet>
            <section className="gry-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                                <h1>Sign up with Aktivitar</h1>
                                <p>Log in to add things to your wishlist and access your bookings from any device</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cont-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">


                                <div className="inp">
                                    <label>First name {this.state.error.first_name !== null ? <span className="error-span"> * {this.state.error.first_name} </span> : ""} </label>
                                    <input type="text" onChange={(e) => { this.handleUpdate(e, 'first_name'); }} />
                                </div>
                                <div className="inp">
                                    <label>Last Name {this.state.error.last_name !== null ? <span className="error-span"> * {this.state.error.last_name} </span> : ""} </label>
                                    <input type="text" onChange={(e) => { this.handleUpdate(e, 'last_name'); }} />
                                </div>
                                <div className="inp">
                                    <label> Email {this.state.error.email !== null ? <span className="error-span"> * {this.state.error.email} </span> : ""} </label>
                                    <input type="text" onChange={(e) => { this.handleUpdate(e, 'email'); }} />
                                </div>
                                <div className="inp">
                                    <label>Password {this.state.error.password !== null ? <span className="error-span"> * {this.state.error.password} </span> : ""} </label>
                                    <input type="password" onChange={(e) => { this.handleUpdate(e, 'password'); }} />
                                </div>
                                <div className="inp">
                                    <label>Confirm password {this.state.error.confim_password !== null ? <span className="error-span"> * {this.state.error.confim_password} </span> : ""} </label>
                                    <input type="password" onChange={(e) => { this.handleUpdate(e, 'confim_password'); }} />
                                </div>

                                <div className="forgot">
                                    <p>
                                        <label>
                                            <input type="checkbox" />
                                            <span>I'd like to receive special offers, inspiration, tips and other updates from Aktivitar.</span>
                                        </label>
                                    </p>
                                </div>
                                <div className="recaptcha-aktivitar">               
                                    <ReCAPTCHA
                                        sitekey="6Ler2V4qAAAAAEv64KkdlNaNtu3UgdN5YqlkS0Mk"
                                        onChange={this.handleRecaptcha} />
                                        
                                        {this.state.capture ? <div className="validate"> <span className="error-span"> * Complete the Captcha before proceeding</span> </div> : ""}
                                </div>
                                <div className="log-btn">
                                    <i className="waves-effect waves-light btn">
                                        <input type="submit" onClick={this.doRegistration} value="Create an Account" />
                                    </i>

                                </div>
                                <div className="sinwith">
                                    <p> Or Sign in with </p>
                                    <div>
                                        <StyledFirebaseAuth
                                            uiConfig={this.uiConfig}
                                            firebaseAuth={firebase.auth()}
                                        />
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="bca">
                                    By creating an account, you agree to Aktivitar's <a href="">Terms & Conditions and Private Policy.</a>
                                </div>
                                <div className="hv-acunt">
                                    <p>Already have an account? <a href="">Log in</a></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {this.props.clientSignUpComplete ? <Redirect to="/login"></Redirect> : null}
            {this.props.loggedIn ? <Redirect to="/account"></Redirect> : null}
            <Footer />

        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SupplierSignUp);