export default function reducer(state = {
    data: null,
    reviewSuccess: false,
    reviewSubmit: false,
    images: []
}, action) {
    switch (action.type) {
        case "STORE_REVIEW_MAIL_MOOD": {
            return { ...state, data: action.data }
        }
        case "REVIEW_ADDED_SUCCESSFULLY": {
            return { ...state, reviewSuccess: true }
        }
        case "REVIEW_SUBMIT_ENABLE": {
            return { ...state, reviewSubmit: action.bool }
        }
        case "REVIEW_IMAGES": {
            return { ...state, images: action.images }
        }
        default:
            break;
    }
    return state
}