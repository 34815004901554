import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { getReviewDetails, reviewMailUpdate, addReviewImage, submitEnable, deleteImage } from '../Actions/WebsiteActions';
import StarRatingComponent from 'react-star-rating-component';
import { Redirect } from 'react-router-dom'
import { SnackBar } from '../Actions/CommonActions'
import Loader from "react-js-loader"
import "./style.css"

const mapStateToProps = (state) => {
    return {
        activeTour: state.Activities.activeTour,
        relatedTours: state.Activities.relatedTours,
        BookNow: state.Cart.bookNow,
        review: state.review
    }
}

const mapDispatchToProps = dispatch => ({
    getReviewDetails: (token) => dispatch(getReviewDetails(token)),
    SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
    reviewMailUpdate: (data) => dispatch(reviewMailUpdate(data)),
    addReviewImage: (file,tid) => dispatch(addReviewImage(file,tid)),
    submitEnable: (bool) => dispatch(submitEnable(bool)),
    deleteImage: (id) => dispatch(deleteImage(id))
});


class ReviewPage extends Component {


    constructor(props) {
        super(props);

        const { token } = this.props.match.params;

        this.state = {
            activity: null,
            value: 0,
            relatedLoaded: false,
            redirect: false,
            loading: false,
            rate: 0,
            feedback: "",
            token: token,
            wordCount: 0,
            file: null,
            images: [],
            submit:true 
        }

        if (token !== null && token !== undefined) {
            this.props.getReviewDetails(token);
        }

    }

    componentDidUpdate() {
        if (this.props.review.data != null && !this.props.review.data && !this.state.redirect) {
            this.setState({ redirect: true })
        }
        if (this.props.review.data == null && !this.state.redirect) {
            this.setState({ loading: true })
        }
        if (this.props.review.data != null && this.state.activity == null) {
            this.setState({ activity: this.props.review.data })
        }
        if(!this.state.submit && this.props.review.reviewSubmit) {
            this.setState({submit:true})
            this.props.submitEnable(false)
            document.getElementById('fileInput').value = '';
            this.setState({file: null})
        }
        if(this.state.images.length!==this.props.review.images.length) {
            this.setState({images: this.props.review.images})
        }
    }

    rateCount = (count) => {
        this.setState({ rate: count })
    }
    handlechange = (event) => {
        const inputText = event.target.value;
        this.setState({
            [event.target.name]: inputText,
            wordCount: inputText.length
        })
    }

    AddReview = () => {
        if (this.state.rate == 0) {
            this.props.SnackBar(true, 'Rate your activity', 'error')
            return false
        } else if (this.state.feedback == "") {
            this.props.SnackBar(true, 'Write your review', 'error')
            return false
        }
        var data = this.state.activity
        this.props.reviewMailUpdate({
            user_id: data.booking.user_id,
            supplier_user_id: data.activity.user_id,
            activity_id: data.activity.id,
            booking_id: data.booking_id,
            review_rating: this.state.rate,
            review_feedback: this.state.feedback,
            token: this.state.token
        }
        )
    }
    handleImage = (event) => {
        console.log(event.target.files[0],"dasdasdasd")
        if(this.state.images.length>2) {
            this.props.SnackBar(true, `Maximum 3 images can upload`, 'error')
            document.getElementById('fileInput').value = '';
            return false
        } else {
            this.setState({ file: event.target.files[0] })
        }
    }
    addImage = () => {
        if(this.state.file!=null) {
            this.props.addReviewImage(this.state.file,this.state.activity.booking_id)
            this.setState({  submit: false })
        }
    }
    deleteImage = (id) => {
        this.props.deleteImage(id)
        this.setState({  submit: false })
    }
    render() {
        console.log(this.state,"this.stateFull")
        return <Fragment>
            <Header />
            <div className="review">
                {this.state.activity == null ?
                    <Loader type="ping-cube" bgColor="#f8a41b" color="#fff" title={"ping-cube"} size={350} /> : null}
                {this.state.activity !== null && this.state.activity !== false && !this.props.review.reviewSuccess ?
                    <div >
                        <div >
                            <div className="rw-img-pg">
                                {this.state.activity !== null && this.state.activity.activity_image !== null ? (
                                    <img src={this.state.activity.activity_image.name} />
                                ) : null}
                            </div>
                            <div className="rw-cont">
                                <h4>{this.state.activity !== null ? this.state.activity.activity.title : ""}</h4>
                                <p>{this.state.activity !== null ? this.state.activity.activity.overview.substr(0, 90) : ""}</p>
                            </div>
                        </div>
                        <div >
                            <h4 className="h4-review">Booking Reference</h4>
                            {this.state.activity !== null ? this.state.activity.booking_ref : null}
                        </div>
                        <div>
                            <h4 className="h4-review-rate">Rate your activity </h4>
                            <div>
                                <StarRatingComponent
                                    name="rate2"
                                    starCount={5}
                                    value={this.state.rate}
                                    renderStarIcon={() => <i style={{ fontSize: 48, fontStyle: 'normal' }} >★</i>}
                                    onStarClick={(newValue) => { this.rateCount(newValue) }}
                                />
                            </div>
                            <div className="frm">
                                <div className='rw-text-area'>
                                    <textarea value={this.state.feedback} name="feedback" rows={4} id="rate" maxlength="500" onChange={this.handlechange} placeholder="Write your review...."></textarea>
                                    <span className="max-text-count">  Word Count: {this.state.wordCount}/500</span>
                                </div>
                            </div>

                            <div class="add-image-box">
                                <div className="review-images">
                                    <div class="added-images" id="insertedImages">
                                        {this.state.images.map((img)=>(
                                            <div className="review-gallery">
                                                {this.state.submit ? <a style={{ cursor: 'pointer' }} onClick={()=>this.deleteImage(img.id)} class="close-button"></a>:
                                                    <a style={{ cursor: 'pointer' }} class="close-button"></a>}
                                                <img src={img.name} />
                                            </div>
                                        ))}
                                    </div>
                                    <div id="add-button-review">
                                        <div class="add-images">
                                            <input type="file"  placeholder="Choose image" id="fileInput" onChange={this.handleImage} />
                                        </div>
                                        <button class="add-image" disabled={!this.state.submit} onClick={this.addImage}>Add</button>
                                    </div>
                                    <div>
                                        <span>All images should be in JPG, PNG, GIF Format.</span>
                                        <span>Maximum 3 images can upload.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='rw-img-btn'>
                                <a onClick={this.AddReview} disabled={!this.state.submit} className="waves-effect waves-light btn modal-trigger"> Submit Review </a>
                            </div>
                        </div>
                    </div> : null}
                {this.props.review.reviewSuccess ? <h2 className="review-success">Review sucessfully added!</h2> : null}
            </div>
            {this.state.activity == false ? <Redirect to="/404"></Redirect> : null}
            <Footer />
        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReviewPage);