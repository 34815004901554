// export const API_URL = 'https://api.aktivitar.mydemoview.com';
export const API_URL = 'https://api.aktivitar.com';
//export const API_URL = 'http://127.0.0.1:8000';
export const API_CLIENT_ID = '2';   
export const API_CLIENT_SECRET = 'swyeJtqdAq1CGxU2FdUsBmCbaSUzApUxYojiKnd3';
export const API_GRANT_TYPE = 'password'; 

// ReCAPTCHA
// Site Key = 6LcGhV4qAAAAAA9wUqXO6GKZ4U7oHtvAR4UhYyY4
// Secret key = 6LcGhV4qAAAAAIPrWNMN3_-hSz75IMWY6r2a4NIV


