import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import CheckoutItem from './Common/CheckoutItem';
import { Helmet } from "react-helmet";
import { MakeBooking, ValidateBookingForm } from '../Actions/WebsiteActions';
import { BookNow, GetCartTotal } from '../Actions/CartActions';
import { Link } from '@material-ui/core';
import RefundPolicy from './Common/refundPolicy';

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})(props => <Checkbox color="default" {...props} />);

const mapStateToProps = (state) => {
	return {
		CartItems: state.Cart.items,
		CartTotal: GetCartTotal(state.Cart.items),
		CartTotalFormatted: state.Cart.totalFormatted,
		activeTour: state.Activities.activeTour,
	}
}

const mapDispatchToProps = dispatch => ({
	MakeBooking: (data) => dispatch(MakeBooking(data)),
	BookNow: (status) => dispatch(BookNow(status)),
	ValidateBookingForm: (data, agreement, refund) => dispatch(ValidateBookingForm(data, agreement, refund))
});

class ShowCheckout extends Component {

	constructor(props) {
		super(props);

		this.state = {
			CartItems: this.props.CartItems,
			firstname: null,
			lastname: null,
			email: null,
			phone: null,
			agreement: false,
			refund: false,
			cartLoaded: false,
			refundPopup: false
		}
		this.props.BookNow(false);
	}

	makeBooking = () => {
		this.props.ValidateBookingForm(this.state.CartItems, this.state.agreement, this.state.refund);
	}

	contactChange = (event, field) => {
		for (var $x = 0; $x < this.state.CartItems.length; $x++) {
			this.handleContactChange(event, field, $x);
		}
	}

	handleContactChange = (event, field, ItemIndex) => {
		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]: {
					...this.state.CartItems[ItemIndex],
					booking_object: {
						...this.state.CartItems[ItemIndex].booking_object,
						[field]: event.target.value
					}
				}


			})

		}, () => { console.log(this.state) });
	}


	handlePickupChange = (event, field, ItemIndex) => {
		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]: {
					...this.state.CartItems[ItemIndex],
					booking_object: {
						...this.state.CartItems[ItemIndex].booking_object,
						[field]: event.target.value
					}
				}


			})

		}, () => { console.log(this.state) });
	}

	handleCopyInfo = (e) => {



		if (e.target.checked) {

			var firstName = this.state.CartItems[0].booking_object.traveller[0].firstname;
			var lastName = this.state.CartItems[0].booking_object.traveller[0].lastname;
			var email = this.state.CartItems[0].booking_object.traveller[0].email;
			var phone = this.state.CartItems[0].booking_object.traveller[0].phone;

			this.setState({
				firstname: firstName,
				lastname: lastName,
				email: email,
				phone: phone
			}, () => {
				for (var $x = 0; $x < this.state.CartItems.length; $x++) {
					this.handleContactChangeGrouped({
						firstname: firstName,
						lastname: lastName,
						email: email,
						phone: phone
					}, $x);
				}
			});



		}
	}


	handleContactChangeGrouped = (data, ItemIndex) => {
		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]: {
					...this.state.CartItems[ItemIndex],
					booking_object: {
						...this.state.CartItems[ItemIndex].booking_object,
						contact_firstname: data.firstname,
						contact_lastname: data.lastname,
						contact_email: data.email,
						contact_phone: data.phone
					}
				}


			})

		}, () => { console.log(this.state) });
	}


	handleTravelerChange = (event, field, ItemIndex, TravelerIndex) => {

		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]: {
					...this.state.CartItems[ItemIndex],
					booking_object: {
						...this.state.CartItems[ItemIndex].booking_object,
						traveller: Object.values({
							...this.state.CartItems[ItemIndex].booking_object.traveller,
							[TravelerIndex]: {
								...this.state.CartItems[ItemIndex].booking_object.traveller[TravelerIndex],
								[field]: event.target.value
							}

						})
					}
				}


			})

		});
	}

	acceptAgreement = (e) => {
		this.setState({
			agreement: e.target.checked
		});
	}

	refundPolicy = (e) => {
		this.setState({
			refund: e.target.checked
		});
	}

	render() {
		const { CartTotal } = this.props;
		return <Fragment>
			<Header />
			<RefundPolicy 
				show={this.state.refundPopup}
				handleClose={()=>this.setState({refundPopup:false})}
			/>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Checkout</title>
			</Helmet>

			<section className="body-gry">
				<div className="container">
					<div className="row">
						<div className="s12">
							<div className="shopping-cart">
								<div className="hed-bed">
									<h1>Checkout</h1>
								</div>
								<div className="mrg-set">
									<div className="row">
										<div className="col l8 m8 s12">
											<div className="Checkout">
												<h4>Tour Details</h4>

												{this.state.CartItems.map((item, index) => {
													return <CheckoutItem
														onTravelerChange={this.handleTravelerChange}
														onPickupChnage={this.handlePickupChange}
														cartItemIndex={index} data={item} />
												})}

												<div className="cin">
													<div>
														<h4>Contact Information</h4>
														<p>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		onChange={this.handleCopyInfo}
																		value="checkedG"
																	/>
																}
																label="Same as Lead Traveler"
															/>
														</p>
													</div>

													<div className="row">
														<div className="col l6 m12 s12">
															<label className="active">First Name<b>*</b></label>
															<input type="text" name="" value={this.state.firstname} onChange={(e) => { this.contactChange(e, 'contact_firstname') }} />
														</div>
														<div className="col l6 m12 s12">
															<label className="active">Last Name<b>*</b></label>
															<input type="text" name="" value={this.state.lastname} onChange={(e) => { this.contactChange(e, 'contact_lastname') }} />
														</div>
													</div>
													<div className="row">
														<div className="col l6 m12 s12">
															<label className="active">Email Address<b>*</b></label>
															<input type="text" name="" value={this.state.email} onChange={(e) => { this.contactChange(e, 'contact_email') }} />
														</div>
														<div className="col l6 m12 s12">
															<label className="active">Contact Number<b>*</b></label>
															<input type="text" name="" value={this.state.phone} onChange={(e) => { this.contactChange(e, 'contact_phone') }} />
														</div>
													</div>
													<p>
														<FormControlLabel
															control={
																<GreenCheckbox
																	onChange={this.acceptAgreement}
																	value="checkedG"
																/>
															}

															label={
																<div>
																	<span>I accept the </span> <Link href="/help" target="_blank" >Terms and Conditions</Link>

																</div>
															}
														/>
													</p>
													<p className="refund-policy">
														<FormControlLabel
															control={
																<GreenCheckbox
																	onChange={this.refundPolicy}
																	value="checkedG"
																/>
															}
														/>
														<label>
															<span>I accept the </span> <Link onClick={() => { this.setState({refundPopup:true}); }}>Refund Policy</Link>
														</label>
													</p>
													<div>
														<i className="waves-effect waves-light btn">
															<input type="submit" onClick={() => { this.makeBooking(); }} value="Confirm &amp; Pay" name="" />
														</i>

													</div>
												</div>
												<div className="hr"></div>
												<div className="ch-cont">
													<ul>
														<li>You will be charged the total amount once your order is confirmed.</li>
														<li>If confirmation isn't received instantly, an authorization for the total amount will be held until your booking is confirmed.</li>
														<li>You can cancel for free up to 24 hours (local time) based on the cancellation policy you book.</li>
													</ul>
													<p>Aktivitar facilitates your booking, but a third party tour operator provides the tour/activity directly to you. Aktivitar does not provide tours or activities itself. By clicking ‘Book Now,’ you are acknowledging that you have read and are bound by Aktivitar’s Customer Terms of Use, Privacy Policy, plus the tour operator’s rules & regulations (see the listing for more details).</p>
												</div>
											</div>
										</div>
										<div className="col l4 m4 s12">
											<div className="pay-div">
												<div className="crd">
													<span>Cart Total</span>
													<img src="img/Visa-Logo.png" />
												</div>
												<div className="py-pric">
													<div>USD {CartTotal}</div>
													<span>No additional taxes & fees</span>
												</div>
												<div className="clear"></div>
												<div className="submit">
													<i className="waves-effect waves-light btn">
														<input type="submit" onClick={() => { this.makeBooking(); }} value="Confirm &amp; Pay" name="" />
													</i>
												</div>
												<div className="rod" style={{ paddingBottom: 0 }}>
													<h4>Review Order Details</h4>
												</div>
												{this.state.CartItems.map((item, index) => {
													return <div className="rod">
														<div>
															<h6>${item.activity.name}</h6>
															<p>${item.amount}</p>
															<div className="clear"></div>
														</div>
														<span>Pick up from Colombo Hotels</span>
														<span>{item.adults} Adults {item.children !== null ? (item.children + ' Children') : null} {item.infants !== null ? (item.infants + ' Infants') : null}</span>
													</div>;
												})}


											</div>
											<div className="hr"></div>
											<div className="low-pr">
												<h6>Lowest Price Guarantee</h6>
												<p>Find it cheaper online? We'll refund the difference</p>
												<h6>24/7 Global Support</h6>
												<p>Get the answers you need, when you need them</p>
											</div>
											<div className="hr"></div>
											<div className="cal-sp">
												<a >
													<span>For Questions call us</span>
													+94 705 579 679
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>

			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ShowCheckout);